<template>
  <div class="background"
  :id="bg"
  :style="{'--color1':this.color1,'--color2':this.color2}"></div>
</template>

<script>
export default {
    name: 'Background',
    props: {
        color1: String,
        color2: String,
        bg: String      
    }
}
</script>

<style>

.background {
    width: 100%;
    height:100%;
    position:fixed;
    top:0px;
    left:0px;

    /*
        https://unsplash.com/photos/E8Ufcyxz514
        https://unsplash.com/photos/koOdUvfGr4c
    */


    opacity:0.5;
    z-index:-1;
}


.background#welcomebg{
    background-image: linear-gradient(var(--color1), var(--color2)), url('../../assets/welcome-background.jpg');
    background-blend-mode:multiply;
    background-size:cover;
    background-position:left;
}

.background#homebg{
    background-image: linear-gradient(var(--color1), var(--color2)), url('../../assets/home-background.jpg');
    background-blend-mode:multiply;
    background-size:cover;
    background-position:left;
}

.background#appbg{
    background-image: linear-gradient(var(--color1), var(--color2)), url('../../assets/app-background.jpg');
    background-blend-mode:multiply;
    background-size:cover;
    background-position:left;  
}

</style>