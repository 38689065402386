<template>
  <div class="home">
    <Init></Init>
  </div>
</template>

<script>
// @ is an alias to /src
import Init from '@/components/Initialize/Init.vue'

export default {
  name: 'Landing',
  components: {
    Init
  }
}
</script>
