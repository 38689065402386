<template>
  <div 
    :class="['btn-border',selected ? 'selected' : '',animation=='no' ? 'no-animation' : '']"
    :style="{'--box-color': this.box_clr,
    '--btn-color':this.btn_clr,
    '--btn-background':this.btn_bg,
    '--btn-def-line-clr':this.btn_lineclr,
    '--btn-hover-color':this.btn_hoverclr
    }"
    @click=select

  >
    <span class="btn-line"></span>
    <span class="btn-line"></span>
    <span class="btn-line"></span>
    <span class="btn-line"></span>
    <div class="btn-text">
        {{btn_text}}
    </div>
  </div>
</template>

<script>
export default {
    name: 'btn_border',
    props: {
        btn_text: String,
        box_clr: String,
        btn_clr: String,
        btn_bg: String,
        btn_lineclr: String,
        btn_hoverclr: String,
        animation: String
    },
    data() {
        return {
            selected: false
        }
    },
    methods: {
        select() {
            this.selected = true
            setTimeout(()=>{
                this.selected = false
            },300)
        }
    }
}
</script>

<style>

.btn-border{
    position:relative;
    text-transform:uppercase;
    margin: 15px;
    padding:25px 50px;
    cursor: pointer;
    transition: .3s;
}

.btn-border .btn-text {
    color: var(--btn-color);
    font-family:var(--font-family-2);
}

.btn-border:hover,
.btn-border:focus,
.btn-border.selected {
    background: var(--btn-background);
    box-shadow: 0 0 10px var(--box-color);
}

.btn-border:hover .btn-text,
.btn-border:focus .btn-text,
.btn-border.selected .btn-text{
    color: var(--btn-hover-color);
}


.btn-border.selected { 
    box-shadow: 0 0 25px var(--box-color);
}

.btn-border .btn-line {
    display: block;
    position: absolute;
    background:var(--btn-def-line-clr);
    --animation-length: 1.5s;
    --left-animation-delay: 0.0s;
    --right-animation-delay: .25s;
    --bottom-animation-delay: .5s;
    --top-animation-delay: .75s;
}

.btn-border.no-animation .btn-line {
    --animation-length: 0s;
}

.btn-border:hover .btn-line,
.btn-border.selected .btn-line {
    background: var(--box-color);
}

/* Left */

.btn-border .btn-line:nth-child(1) {
    left:0;
    bottom:0;
    width:1px;
    height: 100%;
    --scale0: bottom;
    --scale1: top;
    transform-origin: var(--scale1);
    animation: button_animation_Y var(--animation-length) infinite;
    animation-delay: var(--left-animation-delay);
}


/* Bottom */
.btn-border .btn-line:nth-child(2) {
    left:0;
    bottom:0;
    width:100%;
    height: 1px;
    --scale0: right;
    --scale1: left;
    transform-origin: var(--scale1);
    animation: button_animation_X var(--animation-length) infinite;
    animation-delay: var(--bottom-animation-delay);
}

/* Right */
.btn-border .btn-line:nth-child(3) {
    right:0;
    bottom:0;
    width:1px;
    height: 100%;
    --scale0: top;
    --scale1: bottom;
    transform-origin: var(--scale1);
    animation: button_animation_Y var(--animation-length) infinite;
    animation-delay: var(--right-animation-delay);

}

/* Top */
.btn-border .btn-line:nth-child(4) {
    right:0;
    top:0;
    width:100%;
    height: 1px;
    --scale0: left;
    --scale1: right;
    transform-origin: var(--scale1);
    animation: button_animation_X var(--animation-length) infinite;
    animation-delay: var(--top-animation-delay);
}

@keyframes button_animation_Y {
    0% {
        transform: scaleY(0);
    }

    50% {
        transform: scaleY(1);
        transform-origin: var(--scale1) !important;
    }
    100% {
        transform: scaleY(0);
        transform-origin: var(--scale0) !important;
    }
}

@keyframes button_animation_X{
    0% {
        transform: scaleX(0);
    }

    50% {
        transform: scaleX(1);
        transform-origin: var(--scale1) !important;
    }

    100% {
        transform: scaleX(0);
        transform-origin: var(--scale0) !important;
    }
}

</style>