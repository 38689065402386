<template>
  <vuetitle title="Balazs Toth | Developer"></vuetitle>
  <background color1="grey" color2="white" bg="welcomebg"></background>
  <router-view v-slot="{ Component, route }">
    <transition name="routerchange" mode="out-in">
      <div :key="route.name">
        <component :is="Component"></component>
      </div>
    </transition>
  </router-view>
</template>

<script>
import vuetitle from "@/components/Helper/title.vue";
import Background from "@/components/Background/Background.vue";

export default {
  name: "App",
  components: {
    vuetitle,
    Background,
  },
  methods: {
    changeColor() {
      if (
        document.documentElement.style.getPropertyValue(
          "--primary-background-color-s"
        ) == "0%"
      ) {
        document.documentElement.style.setProperty(
          "--primary-background-color-s",
          "100%"
        );
      }
      document.documentElement.style.setProperty(
        "--primary-background-color-h",
        Math.random() * 100
      );
    },

    greyColor() {
      if (
        document.documentElement.style.getPropertyValue(
          "--primary-background-color-s"
        ) == "100%"
      ) {
        document.documentElement.style.setProperty(
          "--primary-background-color-s",
          "0%"
        );
      } else {
        document.documentElement.style.setProperty(
          "--primary-background-color-s",
          "100%"
        );
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100;300&family=Heebo:wght@700&family=Readex+Pro:wght@600&family=Rubik+Glitch&family=Zen+Kaku+Gothic+New:wght@300&display=swap");

::-moz-selection {
  /* Code for Firefox */
  color: rgb(0, 204, 255);
  text-shadow: 0 0 10px rgb(0, 204, 255);
  background: rgb(89, 0, 255) rgb(9, 5, 19);
}

::selection {
  color: rgb(0, 204, 255);
  text-shadow: 0 0 10px rgb(0, 204, 255);
  background: rgb(89, 0, 255) rgb(9, 5, 19);
}

html {
  scrollbar-color: #dac8dc #f1f1f1;
  scrollbar-width: thin;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #dac8dc !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

:root {
  --primary-background-color-h: 0;
  --primary-background-color-s: 20%;
  --primary-background-color-l: 80%;
  --primary-font-color: rgb(0, 0, 0);
  --button-hover-background: hsla(194, 90%, 69%, 0.062);

  --primary-background-color: hsl(
    var(--primary-background-color-h),
    var(--primary-background-color-s),
    var(--primary-background-color-l)
  );
  --contrast-background-color: hsl(
    var(--primary-background-color-h),
    var(--primary-background-color-s),
    calc(var(--primary-background-color-l) - 15%)
  );
  --contrast-button-color: hsl(
    var(--primary-background-color-h),
    var(--primary-background-color-s),
    calc(var(--primary-background-color-l) + 5%)
  );

  --font-family-1: "Readex Pro", sans-serif;
  --font-family-2: "Barlow Semi Condensed", sans-serif;
  --font-family-3: "Rubik Glitch", cursive;
  --font-family-4: "Heebo", sans-serif;
  --font-family-5: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
}

@media screen and (max-width: 1000px) {
  :root {
    font-size: 12px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--primary-background-color);
  height: 100%;
  transition: 0.4s;
}
#app {
  font-family: var(--font-family-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--primary-font-color);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* -------------------------------
  ANIMATION
---------------------------------*/

.routerchange-enter-active {
  animation: routerchange-in 1s reverse;
}

.routerchange-leave-active {
  animation: routerchange-in 1s;
}

@keyframes routerchange-in {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
