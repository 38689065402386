<template>
  <div class="initalize-wrapper">
    <Background
      color1='#ff90de'
      color2='#fffae2'
      bg='welcomebg'>
    </Background>
      <transition name='loading'>
        <Welcome v-if="bln_initialized"></Welcome>
      </transition>
      <transition name='loading'>
        <Loader v-if="!bln_initialized"></Loader>
      </transition>
  </div>
</template>

<script>

import Loader from './Loader.vue'
import Welcome from './Welcome.vue'
import Background from '../Background/Background.vue'

export default {
    name: "Init",
    components: {
        Loader,
        Welcome,
        Background
    },
    data() {
        return {
            bln_initialized: false,
            language_selected: ''
        }
    },
    methods: {

    },
    mounted: function() {
        setTimeout( () => {
            this.bln_initialized = true;
        }, 1500)
    }
}
</script>

<style scoped>

.initalize-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
    position:fixed;
    top:0px;
    left:0px;
}



/*
.initalize-wrapper:after {
  content:'';
  height:100%;
  width:100%;
  position:fixed;
  top:0px;
  left:0px;
  opacity:0.5;
  background:linear-gradient(#eb01a5, #fafafa);
  z-index:-1
}*/

.loading-enter-active {
  animation: bounce-in 0.6s reverse;
}

.loading-leave-active {
  animation: bounce-in 0.6s;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
    opacity:1;
  }
  100% {
    transform: scale(0);
    opacity:0;
  }
}



</style>