<template>
    <div class="welcome-wrapper">
        <div class="welcome-text">
            <transition name='textchange'>
                <div class="text-init" v-if='textinit'>
                    Hi!<br/><span :class="['name-text',mobileuser ? 'mobile' : '']">I'm Balázs Tóth</span>
                </div>
            </transition>
            <transition name='textchange'>
                <div class="text-followup" v-if='!textinit'>
                    Welcome! :)
                </div>
            </transition>
        </div>
        <div class="profile"></div>
        <div class="profile2"></div>
        <transition name='textchange'>
            <btn_border
                    v-if='textinit'
                    id='button1'
                    btn_text='Visit my LinkedIn'
                    box_clr='black'
                    btn_clr='black'
                    btn_hoverclr='white'
                    btn_bg='radial-gradient(ellipse at bottom, #1B2735, #090A0F)'
                    btn_lineclr='black'
                    @click='visitlinkedin'
            ></btn_border>
        </transition>
        <transition name='textchange'>
            <btn_border
                    v-if='textinit'
                    id='button2'
                    btn_text='Check website'
                    box_clr='black'
                    btn_clr='black'
                    btn_hoverclr='white'
                    btn_bg='radial-gradient(ellipse at bottom, #751b1F, #090A0F)'
                    btn_lineclr='black'
                    @click='visitwebsite'
            ></btn_border>
        </transition>
    </div>
</template>

<script>
import btn_border from '../buttons/btn_border.vue'

export default {
    name: 'Welcome',
    components: {
        btn_border,
    },
    data() {
        return {
            textinit: true,
            mobileuser: false
        }
    },
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        visitlinkedin() {
            setTimeout(()=>{
                window.open('https://www.linkedin.com/in/balazstoth94/','_blank')
            },100)
        },

        visitwebsite() {
            this.textinit = false
            setTimeout(()=>{
                this.$router.push('/home')
            },2000)
        }
    },
    mounted: function() {
        this.mobileuser = this.isMobile()
    }
}
</script>

<style>

.welcome-wrapper {
    --welcome-font-color:rgb(130, 187, 204) ;
    z-index:2;
    width:100vw;
    height:100vh;
    display:grid;
    row-gap: 10vh;
    justify-content: center;
    align-items: center;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas: 
    '.  .     .   .'
    '. title      profile .'
    '. button1   button2 .'
    '.  .   .   .';
}

@media screen and (max-width:1000px) {
    .welcome-wrapper {
        grid-template-rows: 1fr 0.3fr 0.3fr .4fr;
        row-gap: 0px;
        grid-template-areas: 
        '. title profile .'
        '.  button1 button1 .'
        '.  button2 button2 .'
        '.  .   .   .'
    }
}

.welcome-text {
    font-size:3rem;
    color: var(--welcome-font-color);
    text-shadow: 0px 0px 2px black;
    font-family: var(--font-family-4);
    grid-area:title;
    text-align: left;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    padding: 15px;
    z-index:3;
}

.profile {
    grid-area: profile;
    background-image: linear-gradient(rgba(0, 0, 0, 0.151), #e732ff5e) ,url('../../assets/profileimg.jpeg');
    background-blend-mode:multiply;
    background-size: cover;
    width:200px;
    height:200px;
    border-radius: 50%;
    border: 5px solid white;
    margin-left:-15px;
    transition: 0.5s;
    cursor:pointer;
    z-index:2;
}

.profile2 {
    grid-area: profile;
    background-image: url('../../assets/profileimg.jpeg');
    background-blend-mode:multiply;
    background-size: cover;
    width:200px;
    height:200px;
    border-radius: 50%;
    border: 3px solid rgb(231, 136, 152);
    margin-left:-15px;
    cursor:pointer;
    z-index:1;
}

.profile:hover,
.profile:focus {
    opacity:0;
}

#button1{
    grid-area: button1
}

#button2{
    grid-area: button2
}

.welcome-text .name-text {
    animation: textanimation 2.2s infinite;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 10px;
}

.welcome-text .name-text.mobile {
    animation: none;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 10px;
}

.welcome-text .text-followup {
    font-family: var(--font-family-1)
}

.text-init,
.text-followup {
    position:absolute;
}

@keyframes textanimation {
    0% {
        font-family:var(--font-family-4);
    }

    40% {
        font-family:var(--font-family-4);
        font-size:3rem;
        color:var(--welcome-font-color)
    }

    41% {
        font-family:var(--font-family-3);
        font-size:2.5rem;
        color:green;
    }

    44% {
        font-family:var(--font-family-4);
         font-size:3rem;
         color:var(--welcome-font-color);
    }

    50% {
        font-family:var(--font-family-4);
        font-size:3rem;
        color:var(--welcome-font-color);
    }

    51% {
        font-family:var(--font-family-3);
        font-size:3.1rem;
        color:purple;
    }

    70% {
        font-family:var(--font-family-4);
        font-size:3rem;
        color:var(--welcome-font-color);
    }

    100% {
        font-family:var(--font-family-4);
    }

}

/* ----------------------
TRANSITION ANIMATION
------------------------- */

.textchange-enter-active {
  animation: textchange 1.5s reverse;
}

.textchange-leave-active {
  animation: textchange 1.5s;
}

@keyframes textchange{
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

</style>