import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueObserveVisibility from 'vue-observe-visibility'

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas,far,fab);
dom.watch();

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

createApp(App).use(VueObserveVisibility).use(store).use(router).component('font-awesome-icon',FontAwesomeIcon).mount('#app')
